<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <Person
        v-for="(person, id) in secondaryPeople"
        v-bind:key="id"
        v-bind:person="person"
        @selected="save"
        @edit="edit"
        :type="'trustee_secondary'"
      >
      </Person>
      <PersonAddQuad @addPerson="setNewPerson" :brotherSister="true">
      </PersonAddQuad>
      <PersonModal
        v-show="show.personModal"
        :person="selectedPerson"
        :show="show.personModal"
        :initialPerson="initialPerson"
        @close="closePersonModal"
      ></PersonModal>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import Person from '@/common/ui/Person'
import PersonModal from '@/views/pages/PeoplePage/PersonModal'
import PersonAddQuad from '@/common/ui/PersonAddQuad'

import { willStringHelpers } from '@/common/mixins/willStringHelpers'

export default {
  name: 'TrusteesSecondary',
  mixins: [willStringHelpers],

  components: {
    NavQuestion,
    NavQuestionButtons,
    Person,
    PersonModal,
    PersonAddQuad
  },
  computed: {
    heading() {
      return 'Who would you like to name as your secondary trustee(s)?'
    },
    number() {
      if (this.partner && this.partner.trustee_sole_primary) return '3'
      return '4'
    },
    mainTrustees() {
      return this.$store.getters.people.filter(
        (person) => person.trustee_primary
      )
    },
    mainTrusteesText() {
      if (this.partner && this.partner.trustee_sole_primary)
        return this.partner.full_name
      if (this.mainTrustees.length === 1)
        return this.listPeople(this.mainTrustees)
      if (this.mainTrustees.length > 1)
        return this.listPeople(this.mainTrustees, ' and ', true)
      return ''
    },
    subHeading() {
      return (
        'These are the people who will take charge should ' +
        this.mainTrusteesText +
        ' pass away before you.   '
      )
    },
    secondaryPeople() {
      return this.$store.getters.people.filter(
        (person) =>
          !person.trustee_primary &&
          !person.under18 &&
          !person.trustee_backup &&
          !person.trustee_sole_primary
      )
    },
    people() {
      return this.$store.getters.people
    },
    partner() {
      return this.$store.getters.partner
    },
    forwardTo() {
      if (this.partner && this.partner.trustee_sole_primary)
        return '/trustees/backup_option'
      return '/trustees/check_and_confirm'
    },
    backTo() {
      if (this.partner && this.partner.trustee_sole_primary)
        return '/trustees/partner'
      else if (!this.partner) return '/trustees/secondary_option'
      return '/trustees/primary'
    },
    isValidated() {
      if (this.people.filter((person) => person.trustee_secondary).length)
        return true
      return false
    }
  },
  data() {
    return {
      loading: false,
      selectedPerson: null,
      initialPerson: null,
      show: {
        personModal: false
      }
    }
  },
  methods: {
    save(person) {
      let personCopy = Object.assign({}, person)
      personCopy.trustee_secondary = !personCopy.trustee_secondary
      this.$store.commit('personAdd', personCopy)
    },
    edit(person) {
      this.selectedPerson = person
      this.show.personModal = true
    },
    closePersonModal() {
      this.selectedPerson = null
      this.show.personModal = false
      this.initialPerson = null
    },
    setNewPerson(value) {
      value.push('trustee_secondary')
      this.initialPerson = value
      this.show.personModal = true
      this.selectedPerson = null
    }
  }
}
</script>
